import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackgroundImage from "gatsby-background-image"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, Link } from "gatsby"
import TestimonialSlider from "../../components/sliders/sap-testimonial-slider/sap-testimonial-slider"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ClientsSlider from "../../components/sliders/clients-slider/clients-slider"

const Applications = ({ data }) => {
  const images = {
    applicationBg: convertToBgImage(getImage(data.applicationBg)),
    application_testimonial_bg: convertToBgImage(
      getImage(data.application_testimonial_bg)
    ),
  }

  const clientImages = {
    amaya: getImage(data.amaya),
    atg: getImage(data.atg),
    baurs: getImage(data.baurs),
    belmond: getImage(data.belmond),
    cbl: getImage(data.cbl),
    cse: getImage(data.cse),
    drp: getImage(data.drp),
    ediri: getImage(data.ediri),
    genie: getImage(data.genie),
    george: getImage(data.george),
    hid: getImage(data.hid),
    hnb: getImage(data.hnb),
    hutch: getImage(data.hutch),
    jk: getImage(data.jk),
    laugfs: getImage(data.laugfs),
    litro: getImage(data.litro),
    mcb: getImage(data.mcb),
    pan: getImage(data.pan),
    shangrila: getImage(data.shangrila),
    singer: getImage(data.singer),
    softlogic: getImage(data.softlogic),
    tokyo: getImage(data.tokyo),
    union: getImage(data.union),
    uoc: getImage(data.uoc),
    water: getImage(data.water),
  }

  const clients_slider = [
    {
      index: 0,
      image: clientImages.amaya,
    },
    {
      index: 1,
      image: clientImages.atg,
    },
    {
      index: 2,
      image: clientImages.baurs,
    },
    {
      index: 3,
      image: clientImages.belmond,
    },
    {
      index: 4,
      image: clientImages.cbl,
    },
    {
      index: 5,
      image: clientImages.cse,
    },
    {
      index: 6,
      image: clientImages.drp,
    },
    {
      index: 7,
      image: clientImages.ediri,
    },
    {
      index: 8,
      image: clientImages.genie,
    },
    {
      index: 9,
      image: clientImages.george,
    },
    {
      index: 10,
      image: clientImages.hid,
    },
    {
      index: 11,
      image: clientImages.hnb,
    },
    {
      index: 12,
      image: clientImages.hutch,
    },
    {
      index: 13,
      image: clientImages.laugfs,
    },
    {
      index: 14,
      image: clientImages.litro,
    },
    {
      index: 15,
      image: clientImages.mcb,
    },
    {
      index: 16,
      image: clientImages.pan,
    },
    {
      index: 18,
      image: clientImages.shangrila,
    },
    {
      index: 19,
      image: clientImages.singer,
    },
    {
      index: 19,
      image: clientImages.softlogic,
    },
    {
      index: 20,
      image: clientImages.tokyo,
    },
    {
      index: 21,
      image: clientImages.union,
    },
    {
      index: 22,
      image: clientImages.uoc,
    },
    {
      index: 23,
      image: clientImages.water,
    },
  ]

  const testimonial_slider = [
    {
      index: 0,
      name: "Samuel Sathiyadaran",
      position: "Information Technology Consultant",
      company: "Alliance Finance Company PLC",
      description:
        "Our association with Tech Pacific started back in 2016, when we required maintenance for our Blade Center RACK. Since then, Tech pacific has carried out our maintenance with superior quality along with technical assistance offered at request. Their high standards in professionalism, quality, consistent on time delivery, and willingness to negotiate renewals is the reason behind Alliance Finance selecting Tech Pacific time and again.",
    },
    {
      index: 1,
      name: "Vidunath Mathagaweera",
      position: "Assistant Manager",
      company: "FairFirst Insurance Ltd",
      description:
        "We chose Tech Pacific to be our partner on D/Emerson Smart Rack Servicing and maintenance in 2018, and working with their team has been quite the experience. They have an extremely professional technical team, consisting of highly skilled workers. Their attention to detail and on-time delivery have consistently stood out, and we will continue to lean on Tech Pacific for their services.",
    },
    {
      index: 2,
      name: "Viraj Liyanage",
      position: "Manager Systems Development",
      company: "MAGA ENGINEERING PVT LTD",
      description:
        "Tech Pacific was responsible for the successful installation of our Data Center Infrastructure back in 2015. What truly stood out to us was the support offered even after providing the solution. We would highly recommend Techpac for their superior products, after sales support and professional staff; having access to all levels of management when required was an unexpected value addition.",
    },
    {
      index: 3,
      name: "Minhaj Hussain",
      position: "IT Manager",
      company: "Lakjaya Micro Finance LTD",
      description:
        "Our relationship with Tech Pacific began in 2018, with the installation of a Network Smartrack Solution. We were pleasantly surprised by their team of specialists equipped with strong technical expertise. Tech Pacific’s timely responsiveness in providing solutions to our problems was a pleasure only surpassed by their professionalism in customer care.",
    },
    {
      index: 4,
      name: "Gayan Perera",
      position: "Deputy Manager IT",
      company: "Siyapatha Finance PLC",
      description:
        "We began our journey with Tech Pacific back in 2016 when purchasing two Emerson Smart Server Cabinets. Our experience over five years has been consistently supportive.Mr. Farzan, Mr. Sasanka, Mr. Chanaka, and the entire team have surpassed our initial expectations. The after-sales service is unparalleled because they attend to breakdowns without delay irrespective of day and night. We would highly recommend Tech Pacific.",
    },
  ]

  return (
    <Layout>
      <Seo title="Applications | Tech Pacific Lanka" />
      <section>
        <div className="main">
          <BackgroundImage {...images.applicationBg} className="sap-header">
            <div className="container">
              <div className="sap-header-content">
                <div className="header-title success-title">Applications</div>
                <div className="header-description">
                  As a certified partner with longstanding expertise in
                  implementation across our Application portfolio, Tech Pacific
                  makes licensing straightforward, provides full visibility of
                  usage and enables clients to realize the full potential of
                  their investment.
                </div>
                <div className="header-buttons header-buttons-success mt-4 d-flex">
                  <Link to="/contact">
                    <button>Connect</button>
                  </Link>
                  <a href="tel:1234567890">
                    <button>Call Now</button>
                  </a>
                </div>
                {/* <div className="learn-more success">
                  LEARN MORE
                  <div className="icon">
                    <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                  </div>
                </div> */}
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>
      <section>
        <div className="success-description-section">
          <div className="success-content container">
            <div className="success-title blue-title text-center">
              Applications
            </div>
            <div className="success-description text-center">
              Tech Pacific offers a range of Applications that give users access
              to a collection of software which facilitates document editing,
              graphic design, video editing, photography editing and web
              development, among others. The range on offer includes Adobe
              applications along with CorelDRAW, TeamViewer, Siemens and
              Arcserve. Often these applications are equipped with mobile apps
              and optional cloud services, supporting developments across a
              convenient range of devices and enabling businesses to increase
              digital efficiency.
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <BackgroundImage
          {...images.application_testimonial_bg}
          className="sap-testimonials"
        >
          <div className="container">
            <div className="sap-testimonials-title about-testimonials-title">
              What Clients Say
            </div>
            <div className="digital-testimonial-description">
              Tech Pacific prides itself on providing game-changing products and
              exceptional service to clients, ensuring they gain the full value
              of their purchases, optimizing licensing and reducing spend.
              Clients agree. Here is what they have to say, in their own words.
            </div>
            <Link to="/contact">
              <button className="digital-button">Talk to us</button>
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <div className="swiper-button-prev1"></div>
            <div className="testimonial-super-main">
              <div className="testimonial-item-main">
                <TestimonialSlider slides={testimonial_slider} />
              </div>
            </div>
            <div className="swiper-button-next1"></div>
          </div>
        </BackgroundImage>
      </section> */}

      <section>
        <div className="retail-applications" id="adobe">
          <div className="container">
            <div className="common-title">Retail Applications</div>
            <div className="application-details-content">
              <div className="image">
                <StaticImage
                  src="../../images/products/applications/adobe.png"
                  width={220}
                  alt="techpac"
                />
              </div>
              <div className="description">
                Adobe’s Creative applications facilitate photo, video, web and
                application design and editing, along with vector graphics,
                video games, cinematic visual effects, motion graphics and
                immersive augmented reality experiences across devices, while
                Adobe’s Acrobat application software offers holistic solutions
                to manage files in Portable Document Format (PDF).
              </div>
              <div className="d-flex align-items-center application-learn-more">
                <a
                  href="https://www.adobe.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="learn-more application">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                </a>
                <Link to="/contact">
                  <Link to="/contact">
                    <button className="application-button">Connect Now</button>
                  </Link>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="sap-grid-main">
          <div className="grid-row d-flex">
            <div className="grid-item light">
              <div className="grid-item-content">
                <div className="image" id="corelDraw">
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/products/applications/corel_d.png"
                    width={300}
                    alt="techpac"
                  />
                </div>
                <div className="sap-grid-details application-grid">
                  <span className="sap-grid-description">
                    A vector based graphics software mainly used for marketing
                    and advertising purposes, CorelDRAW is popular in the
                    professional space to help digitally create a diverse range
                    of content including brochures, newsletters, logos, flexes,
                    invitations and more
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <a
                    href="https://www.coreldraw.com/en/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </a>
                  <Link to="/contact">
                    <button className="application-button">Connect Now</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="grid-item dark">
              <div className="grid-item-content">
                <div className="image" id="teamViewer">
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/products/applications/team_viewer.png"
                    width={300}
                    alt="techpac"
                  />
                </div>
                <div className="sap-grid-details application-grid">
                  <span className="sap-grid-description">
                    A remote support solution that gives access to computers or
                    mobile devices located anywhere in the world, TeamViewer has
                    a user experience equivalent to being physically present. It
                    is a comprehensive, remote access, remote control and remote
                    support solution that works with almost every desktop and
                    mobile platform, including Windows, macOS, Android and iOS.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <a
                    href="https://www.teamviewer.com/en/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </a>
                  <Link to="/contact">
                    <button className="application-button">Connect Now</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-row d-flex">
            <div className="grid-item application-grid-item dark">
              <div className="grid-item-content">
                <div className="common-title">Application Software</div>
                <div className="image" id="arcserve">
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/products/applications/arcserve.png"
                    width={300}
                    alt="techpac"
                  />
                </div>
                <div className="sap-grid-details application-grid">
                  <span className="sap-grid-description">
                    Arcserve is a comprehensive storage backup solution which
                    provides complete control and visibility from one management
                    console in a central location, for diversely scaled
                    enterprises across platforms and Organizations.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <a
                    href="https://www.arcserve.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </a>
                  <Link to="/contact">
                    <button className="application-button">Connect Now</button>
                  </Link>
                </div>
              </div>
            </div>
            <div id="Siemens" className="grid-item application-grid-item light">
              <div className="grid-item-content">
                <div className="image" id="arcserve">
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/products/applications/siemens.png"
                    width={300}
                    alt="techpac"
                  />
                </div>
                <div className="sap-grid-details application-grid">
                  <span className="sap-grid-description">
                    Siemens Advanced Planning software is a strategic
                    decision-making tool designed to aid in planning. It impacts
                    direction of production including workforce, resources and
                    factory capacity planning. The software considers forecast
                    and long-term orders while supporting decision-making
                    pertaining to feasibility.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <a
                    href="https://www.siemens.com/global/en.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </a>
                  <Link to="/contact">
                    <button className="application-button">Connect Now</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="grid-row d-flex">
            <div id="Siemens" className="grid-item application-grid-item light">
              <div className="grid-item-content">
                <div className="image" id="arcserve">
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/products/applications/Cadmate_logo.png"
                    width={300}
                    alt="techpac"
                  />
                </div>
                <div className="sap-grid-details application-grid">
                  <span className="sap-grid-description">
                    CADMATE 2021 version optimizes 3D functionalities by adding
                    a series of new commands Other new features like Quick
                    Measure, Import/Export custom settings and point cloud
                    greatly improve user's efficiency to obtain dimensional
                    data, transfer user settings and view point cloud data. In
                    addition, the significant improvement in the tool palette
                    and Mtext editor will lighten workload tasks and design
                    process.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <a
                    href="https://www.cadmate.org/cadmate-2021"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </a>
                  <Link to="/contact">
                    <button className="application-button">Connect Now</button>
                  </Link>
                </div>
              </div>
            </div>
            <div
              id="Siemens"
              className="grid-item application-grid-item dark"
            ></div>
          </div>
        </div>
      </section>
      <section>
        <div className="sap-client">
          <div className="">
            <div className="sap-clients-slider container">
              Curated Clientele
            </div>
            <div className="d-flex align-items-center">
              <div className="swiper-button-prev1"></div>
              <div className="client-slider-item">
                <ClientsSlider slides={clients_slider} />
              </div>
              <div className="swiper-button-next1"></div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="get-in-touch purple d-flex align-items-center justify-content-center">
          <div className="get-in-touch-content text-center container">
            <p>Get In Touch With Our Experts</p>
            <Link to="/contact">
              <button
                className="btn btn-primary blue-contact-footer"
                type="button"
              >
                Let's Get to Work
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Applications {
    applicationBg: file(
      relativePath: { eq: "products/applications/applicationBg.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    application_testimonial_bg: file(
      relativePath: {
        eq: "products/applications/application_testimonial_bg.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    amaya: file(
      relativePath: { eq: "products/applications/clients/amaya.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    atg: file(relativePath: { eq: "products/applications/clients/atg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    baurs: file(
      relativePath: { eq: "products/applications/clients/baurs.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    belmond: file(
      relativePath: { eq: "products/applications/clients/belmond.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cbl: file(relativePath: { eq: "products/applications/clients/cbl.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cse: file(relativePath: { eq: "products/applications/clients/cse.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    drp: file(relativePath: { eq: "products/applications/clients/drp.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ediri: file(
      relativePath: { eq: "products/applications/clients/ediri.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    genie: file(
      relativePath: { eq: "products/applications/clients/genie.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    george: file(
      relativePath: { eq: "products/applications/clients/george.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    hid: file(relativePath: { eq: "products/applications/clients/hid.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    hnb: file(relativePath: { eq: "products/applications/clients/hnb.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    hutch: file(
      relativePath: { eq: "products/applications/clients/hutch.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    jk: file(relativePath: { eq: "products/applications/clients/jk.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    laugfs: file(
      relativePath: { eq: "products/applications/clients/laugfs.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    litro: file(
      relativePath: { eq: "products/applications/clients/litro.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    mcb: file(relativePath: { eq: "products/applications/clients/mcb.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pan: file(relativePath: { eq: "products/applications/clients/pan.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    shangrila: file(
      relativePath: { eq: "products/applications/clients/shangrila.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    singer: file(
      relativePath: { eq: "products/applications/clients/singer.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    softlogic: file(
      relativePath: { eq: "products/applications/clients/softlogic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tokyo: file(
      relativePath: { eq: "products/applications/clients/tokyo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    union: file(
      relativePath: { eq: "products/applications/clients/union.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    uoc: file(relativePath: { eq: "products/applications/clients/uoc.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    water: file(
      relativePath: { eq: "products/applications/clients/water.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default Applications
